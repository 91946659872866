<template>
  <div>
    <vs-input
      v-validate="'required|email|min:3'"
      data-vv-validate-on="blur"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Email"
      v-model="email"
      class="w-full"
    />

    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
      v-on:keyup.enter="loginJWT"
      data-vv-validate-on="blur"
      v-validate="'required|min:4|max:64'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Password"
      v-model="password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>
    <br />
    <router-link to="/pages/forgot-password">Forgot Password?</router-link>
    <br />
    <br />

    <!-- <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>
    </div> -->
    <div class="flex flex-wrap justify-between mb-3">
      <vs-button type="border" @click="registerUser">Create an account</vs-button>
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
export default {
  inject: ['$validator'],
  data() {
    return {
      email: null,
      password: null,
      checkbox_remember_me: false,
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    },
  },
  created() {
    this.$store.dispatch('auth/logout')
  },
  mounted() {
    // window.addEventListener('keydown', event => this.onEnterPressed(event))
    // window.removeEventListener('keydown', event => this.onEnterPressed(event))
  },
  beforeDestroy() {
    // console.log('bEFORE DESTROY')
    // window.removeEventListener('keydown', event => this.onEnterPressed(event))
  },

  methods: {
    onEnterPressed(event) {
      if (event.key === 'Enter') {
        this.loginJWT()
      }
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        })
        return false
      }
      return true
    },
    async loginJWT() {
      // compute unique uuid per session
      localStorage.setItem('editorSessionId', uuidv4())

      // if (!this.checkLogin()) return
      // Loading
      this.$vs.loading()
      const params = {
        // checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
      }
      try {
        await this.$store.dispatch('auth/loginJWT', params)
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    registerUser() {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    },
  },
}
</script>
